
import { TAG_COLOR_ORDER } from '@icepanel/app-canvas'
import { ModelConnection, ModelObject, Tag, TagGroup } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as icons from '../../helpers/icons'
import TagChip from '../tag.vue'
import GroupIconMenu from './group-icon-menu.vue'
import GroupName from './group-name.vue'
import TagListItem from './tag-list-item.vue'

@Component({
  components: {
    GroupIconMenu,
    GroupName,
    TagChip,
    TagListItem
  },
  name: 'TagPickerGroupListItem'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly group!: TagGroup
  @Prop() readonly tags!: Tag[]
  @Prop() readonly objects!: (ModelObject | ModelConnection)[]

  @Ref() readonly groupIconMenuRef!: GroupIconMenu
  @Ref() readonly groupNameRef!: GroupName
  @Ref() readonly tagListItemRefs!: TagListItem[] | undefined

  hover = false
  hoverDelete = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get iconMap () {
    return icons.tagGroups
  }

  async createTag () {
    const groupTags = Object
      .values(this.tagModule.tags)
      .sort((a, b) => a.index === b.index ? a.name > b.name ? 1 : -1 : a.index > b.index ? 1 : -1)
      .filter(o => o.groupId === this.group.id)
    const lastGroupTag = groupTags[groupTags.length - 1]

    const usedColors = this.tags.map(o => o.color)
    const nextColor = TAG_COLOR_ORDER.find(o => !usedColors.includes(o)) || TAG_COLOR_ORDER[Math.floor((Math.random() * TAG_COLOR_ORDER.length))]

    const { tag, tagUpsert } = this.tagModule.generateTag(this.currentLandscape.id, this.currentVersion.id, {
      color: nextColor,
      groupId: this.group.id,
      index: lastGroupTag ? lastGroupTag.index + 1 : 0,
      name: ''
    })
    this.tagModule.setTag(tag)
    this.editorModule.addToTaskQueue({
      func: () => this.tagModule.tagUpsert({
        landscapeId: this.currentLandscape.id,
        props: tagUpsert,
        tagId: tag.id,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks: [{
        id: tag.id,
        type: 'tag-delete'
      }, {
        route: this.$route,
        type: 'navigation'
      }],
      tasks: [{
        id: tag.id,
        props: tagUpsert,
        type: 'tag-create'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    analytics.tagCreate.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: tag.color,
      tagGroupIcon: this.group.icon,
      tagGroupName: this.group.name,
      tagName: tag.name,
      tagNameLength: tag.name.length
    })

    await this.$nextTick()

    this.tagListItemRefs?.find(o => o.tag.id === tag.id)?.tagRef.startEditing()
  }
}
