
import { TAG_COLOR_ACTIVE, TAG_COLOR_BACKGROUND } from '@icepanel/app-canvas'
import { ModelConnection, ModelObject, PermissionType, TagGroup, TagGroupRequired } from '@icepanel/platform-api-client'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as icons from '../../helpers/icons'
import TagChip from '../tag.vue'
import GroupListItem from './group-list-item.vue'

@Component({
  components: {
    GroupListItem,
    Menu,
    OrganizationUpgradeMenu,
    TagChip
  },
  name: 'TagPicker'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu
  @Ref() readonly groupListItemRefs!: GroupListItem[]
  @Ref() readonly tagRefs!: TagChip[]
  @Ref() readonly tagContainerRef!: HTMLElement

  @Prop() readonly objects!: (ModelObject | ModelConnection)[]
  @Prop() readonly permission!: PermissionType
  @Prop({ default: 20 }) readonly height!: number

  visible = false
  width = 0
  hiddenCount = 0

  searchTerm = ''
  searchTermModel = ''
  searchFocused = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get tagDeleteDialog () {
    return this.$queryValue('tag_delete_dialog')
  }

  get tagGroupDeleteDialog () {
    return this.$queryValue('tag_group_delete_dialog')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get organizationUpgradeDialog () {
    return this.$queryValue('organization_upgrade_dialog')
  }

  get tagPickerMenu () {
    return this.$queryValue('tag_picker_menu')
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get maxGroups () {
    return this.currentLandscape?.organizationId ? undefined : 2
  }

  get pinnedTag () {
    return this.tags.some(o => this.overlayIdsPinned.includes(o.handleId))
  }

  get groupCount () {
    return Object.keys(this.tagModule.tagGroups).length
  }

  get tagGroupLimitReached () {
    return this.groupCount >= this.currentOrganizationLimits.tagGroups
  }

  get colorMap () {
    return TAG_COLOR_ACTIVE
  }

  get colorBackgroundMap () {
    return TAG_COLOR_BACKGROUND
  }

  get groupIcons () {
    return Object
      .entries(this.tagModule.tagGroups)
      .reduce<Record<string, string>>((p, [id, tagGroup]) => ({
        ...p,
        [id]: icons.tagGroups[tagGroup.icon]
      }), {})
  }

  get tagGroups () {
    return Object
      .values(this.tagModule.tagGroups)
      .map(o => {
        const tags = Object
          .values(this.tagModule.tags)
          .filter(t => {
            return t.groupId === o.id && (
              (!this.searchTerm || t.name.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
              (!this.searchTerm || o.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
            )
          })
          .sort((a, b) => {
            if (a.index === b.index) {
              if (a.name === b.name) {
                return a.id.localeCompare(b.id)
              } else {
                return a.name.localeCompare(b.name)
              }
            } else {
              return a.index > b.index ? 1 : -1
            }
          })
        return {
          group: o,
          tags
        }
      })
      .filter(o => o.tags.length || !this.searchTerm || o.group.name.toLowerCase().includes(this.searchTerm.toLowerCase()))
      .sort((a, b) => {
        if (a.group.index === b.group.index) {
          if (a.group.name === b.group.name) {
            return a.group.id.localeCompare(b.group.id)
          } else {
            return a.group.name.localeCompare(b.group.name)
          }
        } else {
          return a.group.index > b.group.index ? 1 : -1
        }
      })
  }

  get tags () {
    const tagIds = [...new Set(this.objects.map(o => o.tagIds).flat())]
    return tagIds
      .map(o => this.tagModule.tags[o])
      .filter(o => o)
      .sort((a, b) => this.overlayIdsPinned.includes(a.id) && !this.overlayIdsPinned.includes(b.id) ? -1 : 0)
  }

  @Watch('tags')
  onTagsChanged () {
    this.menuRef.updateDimensions()
    this.$nextTick(() => {
      this.resize()
    })
  }

  @Watch('pinnedTag')
  onPinnedTagChanged () {
    this.menuRef.updateDimensions()
    this.$nextTick(() => {
      this.resize()
    })
  }

  @Watch('tagGroups')
  onTagGroupsChanged () {
    this.menuRef.updateDimensions()
  }

  mounted () {
    this.$nextTick(() => {
      this.resize()
    })
  }

  opened () {
    this.searchTerm = ''
    this.searchTermModel = ''

    this.visible = true

    analytics.tagPickerMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagObjectCount: this.objects.length,
      tagObjectTypes: this.objects.map(o => 'type' in o ? o.type : 'connection')
    })
  }

  closed () {
    this.visible = false

    this.groupListItemRefs?.forEach(g => {
      g.tagListItemRefs?.forEach(t => {
        t.tagRef.stopEditing()
      })
    })
  }

  setSearchTermDebounce = debounce(this.setSearchTerm.bind(this), 300)

  setSearchTerm (term: string) {
    this.searchTerm = term
  }

  async createTagGroup () {
    const perspectiveIcons = Object.keys(icons.tagGroups) as TagGroup['icon'][]
    const icon = perspectiveIcons[Math.floor((Math.random() * perspectiveIcons.length))]

    const tagGroupCreate: TagGroupRequired = {
      icon,
      index: Object.keys(this.tagModule.tagGroups).length,
      name: ''
    }
    const { tagGroup, tagGroupUpsert } = this.tagModule.generateTagGroup(this.currentLandscape.id, this.currentVersion.id, tagGroupCreate)
    this.tagModule.setTagGroup(tagGroup)
    this.editorModule.addToTaskQueue({
      func: () => this.tagModule.tagGroupUpsert({
        landscapeId: this.currentLandscape.id,
        props: tagGroupUpsert,
        tagGroupId: tagGroup.id,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks: [{
        id: tagGroup.id,
        type: 'tag-group-delete'
      }, {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            tag_picker_menu: null
          }
        },
        type: 'navigation'
      }],
      tasks: [{
        id: tagGroup.id,
        props: tagGroupCreate,
        type: 'tag-group-create'
      }, {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            tag_picker_menu: null
          }
        },
        type: 'navigation'
      }]
    })

    await this.$nextTick()

    this.groupListItemRefs.find(o => o.group.id === tagGroup.id)?.groupNameRef.startEditing()
  }

  resize () {
    this.width = this.$el.clientWidth
    this.hiddenCount = this.tagRefs?.filter(o => (o.$el as HTMLElement).offsetTop > this.height + 2).length || 0
  }
}
