
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'

import hexToRgb from '@/helpers/hex-to-rgb'

@Component({
  name: 'Tag'
})
export default class extends Vue {
  @Prop() readonly title!: string
  @Prop() readonly subtitle!: string | undefined
  @Prop() readonly color!: string | undefined
  @Prop() readonly background!: string | undefined
  @Prop() readonly icon?: string
  @Prop() readonly image?: string
  @Prop({ default: false, type: Boolean }) readonly dashed!: boolean
  @Prop({ default: false, type: Boolean }) readonly editable!: boolean
  @Prop({ default: false, type: Boolean }) readonly strike!: boolean

  @Ref() readonly nameFieldRef!: { $refs: { input: HTMLInputElement } }

  model = ''
  editing = false
  textWidth = 24

  get backgroundColor () {
    if (this.color && this.background) {
      const rgba = hexToRgb(this.color)
      if (rgba) {
        const color = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, 0.1)`
        return `linear-gradient(${color}, ${color}), linear-gradient(${this.background}, ${this.background})`
      }
    }
    return ''
  }

  mounted () {
    this.model = this.title
  }

  @Watch('title')
  onTitleChanged (newVal: string, prevVal: string) {
    if (newVal !== prevVal && this.model !== newVal) {
      this.model = newVal
    }
  }

  @Watch('model')
  modelChanged () {
    this.calcTextWidth()
  }

  async startEditing () {
    if (!this.editing && this.editable) {
      this.editing = true
      this.$emit('editing', this.editing)

      await this.$nextTick()
      this.nameFieldRef.$refs.input.select()
    }
  }

  stopEditing () {
    if (this.editing) {
      this.editing = false
      this.nameFieldRef.$refs.input.blur()
      this.$emit('editing', this.editing)
      this.$emit('submit', this.model)
    }
  }

  resetModel () {
    if (this.model !== this.title) {
      this.model = this.title
    }
  }

  calcTextWidth () {
    const div = document.createElement('pre')

    div.style.position = 'absolute'
    div.style.visibility = 'hidden'
    div.style.whiteSpace = 'pre-wrap'
    div.style.left = '-9999px'

    div.style.fontFamily = 'Roboto'
    div.style.fontSize = '0.75rem'
    div.style.letterSpacing = '0'
    div.style.fontWeight = '500'

    div.textContent = this.model || 'Tag'

    document.body.appendChild(div)
    this.textWidth = parseInt(window.getComputedStyle(div).width) || 0
    this.textWidth++
    document.body.removeChild(div)
  }

  submitKey (event: KeyboardEvent) {
    event.preventDefault()
    event.stopPropagation()

    this.stopEditing()
  }
}
