
import { TAG_COLOR_ACTIVE, TAG_COLOR_BACKGROUND, TAG_COLOR_INACTIVE } from '@icepanel/app-canvas'
import { ModelConnection, ModelObject, Tag, TagGroup, TagPartial, Task, TaskModelConnectionUpdate, TaskModelObjectUpdate } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as icons from '../../helpers/icons'
import TagColorMenu from '../color-menu.vue'
import TagChip from '../tag.vue'

@Component({
  components: {
    TagChip,
    TagColorMenu
  },
  name: 'TagPickerTagListItem'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly group!: TagGroup
  @Prop() readonly tag!: Tag
  @Prop() readonly objects!: (ModelObject | ModelConnection)[]

  @Ref() readonly tagRef!: TagChip
  @Ref() readonly tagColorMenuRef!: TagColorMenu

  editing = false
  hover = false
  hoverDelete = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get assigned () {
    return this.objects.every(o => o.tagIds.includes(this.tag.id))
  }

  get semiAssigned () {
    return this.objects.some(o => o.tagIds.includes(this.tag.id))
  }

  get colorMap () {
    return this.assigned || this.semiAssigned || this.hover || this.editing ? TAG_COLOR_ACTIVE : TAG_COLOR_INACTIVE
  }

  get colorBackgroundMap () {
    return TAG_COLOR_BACKGROUND
  }

  get icon () {
    return icons.tagGroups[this.group.icon]
  }

  saveTag () {
    this.updateTag({
      color: this.tagColorMenuRef.model,
      name: this.tagRef.model || 'Unnamed tag'
    })
  }

  updateTag (props: Omit<TagPartial, 'commit'>) {
    const revertTasks: Task[] = [{
      id: this.tag.id,
      props: {
        color: this.tag.color,
        groupId: this.tag.groupId,
        index: this.tag.index,
        name: this.tag.name
      },
      type: 'tag-update'
    }, {
      route: this.$route,
      type: 'navigation'
    }]

    const { tag, tagUpdate } = this.tagModule.generateTagCommit(this.tag.id, props)
    this.tagModule.setTagVersion(tag)
    this.editorModule.addToTaskQueue({
      func: () => this.tagModule.tagUpdate({
        landscapeId: this.currentLandscape.id,
        props: tagUpdate,
        tagId: this.tag.id,
        versionId: this.currentVersion.id
      })
    })

    this.editorModule.addTaskList({
      revertTasks,
      tasks: [{
        id: tag.id,
        props: tagUpdate,
        type: 'tag-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]
    })

    analytics.tagUpdate.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagColor: props.color,
      tagGroupIcon: this.group.icon,
      tagGroupName: this.group.name,
      tagName: props.name,
      tagNameLength: props.name?.length
    })
  }

  async toggleTag () {
    if (this.editing) {
      return
    }

    const tasks: Task[] = []
    const revertTasks: Task[] = []

    const func: (() => Promise<any>)[] = []

    if (this.currentDiagram?.status === 'draft') {
      const tagUpdates: (TaskModelObjectUpdate | TaskModelConnectionUpdate)[] = []

      this.objects.forEach(o => {
        if ('type' in o) {
          if (!this.assigned && !o.tagIds.includes(this.tag.id)) {
            tagUpdates.push({
              id: o.id,
              props: {
                tagIds: [...o.tagIds, this.tag.id]
              },
              type: 'model-object-update'
            })
          } else if (this.assigned && o.tagIds.includes(this.tag.id)) {
            tagUpdates.push({
              id: o.id,
              props: {
                tagIds: o.tagIds.filter(o => o !== this.tag.id)
              },
              type: 'model-object-update'
            })
          }
        } else {
          if (!this.assigned && !o.tagIds.includes(this.tag.id)) {
            tagUpdates.push({
              id: o.id,
              props: {
                tagIds: [...o.tagIds, this.tag.id]
              },
              type: 'model-connection-update'
            })
          } else if (this.assigned && o.tagIds.includes(this.tag.id)) {
            tagUpdates.push({
              id: o.id,
              props: {
                tagIds: o.tagIds.filter(o => o !== this.tag.id)
              },
              type: 'model-connection-update'
            })
          }
        }
      })

      revertTasks.push({
        id: this.currentDiagram.id,
        props: {
          tasksProposed: {
            $append: tagUpdates.map(o => ({
              id: o.id,
              props: {
                tagIds: this.modelModule.objects[o.id]?.tagIds || this.modelModule.connections[o.id]?.tagIds
              },
              type: o.type
            }))
          }
        },
        type: 'diagram-content-update'
      })

      const { diagramContent, diagramContentUpdate } = this.diagramModule.generateDiagramContentCommit(this.currentDiagram.id, {
        tasksProposed: {
          $append: tagUpdates
        }
      })
      this.diagramModule.setDiagramContentVersion(diagramContent)

      func.push(() => this.diagramModule.diagramContentUpdate({
        diagramId: diagramContent.id,
        landscapeId: this.currentLandscape.id,
        props: diagramContentUpdate,
        versionId: this.currentVersion.id
      }))

      tasks.push({
        id: diagramContent.id,
        props: diagramContentUpdate,
        type: 'diagram-content-update'
      })
    } else {
      this.objects.forEach(o => {
        let tagIds: string[]
        if (this.assigned) {
          tagIds = o.tagIds.filter(o => o !== this.tag.id)
        } else {
          tagIds = [...o.tagIds, this.tag.id]
        }

        if (this.assigned) {
          analytics.tagObjectRemove.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId],
            tagColor: this.tag.color,
            tagGroupIcon: this.group.icon,
            tagGroupName: this.group.name,
            tagName: this.tag.name,
            tagObjectType: 'type' in o ? o.type : 'connection'
          })
        } else {
          analytics.tagObjectAdd.track(this, {
            landscapeId: [this.currentLandscape.id],
            organizationId: [this.currentLandscape.organizationId],
            tagColor: this.tag.color,
            tagGroupIcon: this.group.icon,
            tagGroupName: this.group.name,
            tagName: this.tag.name,
            tagObjectType: 'type' in o ? o.type : 'connection'
          })
        }

        if ('type' in o) {
          revertTasks.push({
            id: o.id,
            props: {
              tagIds: o.tagIds
            },
            type: 'model-object-update'
          })

          const { object, objectUpdate } = this.modelModule.generateObjectCommit(o.id, {
            tagIds
          })

          this.modelModule.setObjectVersion(object)

          func.push(() => this.modelModule.objectUpdate({
            landscapeId: this.currentLandscape.id,
            objectId: o.id,
            props: objectUpdate,
            versionId: this.currentVersion.id
          }))

          tasks.push({
            id: object.id,
            props: objectUpdate,
            type: 'model-object-update'
          })
        } else {
          revertTasks.push({
            id: o.id,
            props: {
              tagIds: o.tagIds
            },
            type: 'model-connection-update'
          })

          const { connection, connectionUpdate } = this.modelModule.generateConnectionCommit(o.id, { tagIds })
          this.modelModule.setConnectionVersion(connection)

          func.push(() => this.modelModule.connectionUpdate({
            connectionId: o.id,
            landscapeId: this.currentLandscape.id,
            props: connectionUpdate,
            versionId: this.currentVersion.id
          }))

          tasks.push({
            id: connection.id,
            props: connectionUpdate,
            type: 'model-connection-update'
          })
        }
      })
    }

    this.editorModule.addToTaskQueue({ func })

    this.editorModule.addTaskList({
      revertTasks: [{
        tasks: revertTasks,
        type: 'batch'
      }, {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            tag_picker_menu: null
          }
        },
        type: 'navigation'
      }],
      tasks: [{
        tasks,
        type: 'batch'
      }, {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            tag_picker_menu: null
          }
        },
        type: 'navigation'
      }]
    })
  }
}
