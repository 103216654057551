
import { TagGroup, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'

@Component({
  name: 'TagPickerGroupName'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly nameField!: HTMLElement

  @Prop() readonly group!: TagGroup

  model = ''
  editing = false

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  @Watch('group.name')
  onGroupNameChanged (newVal: string, prevVal: string) {
    if (newVal !== prevVal) {
      this.model = newVal || ''
    }
  }

  mounted () {
    this.model = this.group.name || ''
  }

  startEditing () {
    this.editing = true
    this.nameField.focus()
  }

  stopEditing () {
    this.editing = false
    this.nameField.blur()

    if (!this.model.trim()) {
      this.updateName('Unnamed group')
    }
  }

  updateName (name: string) {
    if (this.group.name !== name) {
      const revertTasks: Task[] = [{
        id: this.group.id,
        props: {
          name: this.group.name
        },
        type: 'tag-group-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { tagGroup, tagGroupUpdate } = this.tagModule.generateTagGroupCommit(this.group.id, { name })
      this.tagModule.setTagGroupVersion(tagGroup)
      this.editorModule.addToTaskQueue({
        func: () => this.tagModule.tagGroupUpdate({
          landscapeId: this.currentLandscape.id,
          props: tagGroupUpdate,
          tagGroupId: this.group.id,
          versionId: this.currentVersion.id
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: tagGroup.id,
          props: tagGroupUpdate,
          type: 'tag-group-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })

      analytics.tagGroupUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        tagGroupName: name,
        tagGroupNameLength: name.length
      })
    }
  }

  enterKey (event: KeyboardEvent) {
    if (!event.shiftKey) {
      event.preventDefault()
      this.nameField.blur()
    }
  }
}
