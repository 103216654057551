
import { TagGroup, Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { TagModule } from '@/modules/tag/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import * as icons from '../../helpers/icons'

@Component({
  components: {
    Menu
  },
  name: 'TagPickerGroupIconMenu'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu

  @Prop() readonly group!: TagGroup
  @Prop() readonly activator!: (() => HTMLElement | undefined) | undefined
  @Prop() readonly openOnClick: boolean | undefined
  @Prop() readonly top: boolean | undefined
  @Prop() readonly nudgeTop!: string | number
  @Prop() readonly nudgeLeft!: string | number

  get icons (): TagGroup['icon'][][] {
    return [
      ['microchip', 'rocket', 'calendar-check', 'calendar-times', 'globe', 'lock'],
      ['database', 'server', 'cloud', 'network-wired', 'wifi', 'cog'],
      ['mobile', 'laptop-code', 'toolbox', 'users', 'robot', 'sack-dollar'],
      ['file', 'exclamation-triangle', 'star', 'times', 'plus', 'minus'],
      ['poo', 'trash', 'lightbulb', 'bug', 'sledding', 'snowman']
    ]
  }

  get iconMap () {
    return icons.tagGroups
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  opened () {
    analytics.tagGroupIconMenu.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      tagGroupIcon: this.group.icon,
      tagGroupName: this.group.name
    })
  }

  updateIcon (icon: TagGroup['icon']) {
    if (this.group.icon !== icon) {
      const revertTasks: Task[] = [{
        id: this.group.id,
        props: {
          icon: this.group.icon
        },
        type: 'tag-group-update'
      }, {
        route: this.$route,
        type: 'navigation'
      }]

      const { tagGroup, tagGroupUpdate } = this.tagModule.generateTagGroupCommit(this.group.id, { icon })
      this.tagModule.setTagGroupVersion(tagGroup)
      this.editorModule.addToTaskQueue({
        func: () => this.tagModule.tagGroupUpdate({
          landscapeId: this.currentLandscape.id,
          props: tagGroupUpdate,
          tagGroupId: this.group.id,
          versionId: this.currentVersion.id
        })
      })

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: tagGroup.id,
          props: tagGroupUpdate,
          type: 'tag-group-update'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })

      analytics.tagGroupUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        tagGroupIcon: icon
      })

      this.menuRef.close()
    }
  }
}
